:root {
    --loading-grey: #dfdfdf;
}

.skLoading {
  background-color: var(--loading-grey);
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.smooth {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.3, 0, 0.4, 1);
  transition-timing-function: cubic-bezier(0.3, 0, 0.4, 1);
  opacity: 0;
}

.smooth.loaded {
  opacity: 1;
}
